<template>
  <v-card elevation="2" class="pa-2 d-flex justify-center">
    <v-btn text color="primary" @click.native="$router.push(link)">
      <v-icon left>mdi-arrow-left</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: "GoBackCard",
  props: { link: String },
};
</script>